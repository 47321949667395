export const globalStyles = `
    body {
        margin: 0px;
        font-family: 'Pragati Narrow', sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    img {
        max-width: 100%
    }

    h2 {
      font-size: 51px;
      line-height: 100%;
      margin-top: 37px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 26px;

      @media (min-width: 768px) {
        font-size: 31px;
      }
      
      line-height: 100%;
      margin-top: 20px;
      margin-bottom: 21px;
    }
    
    p {
      font-size: 20px;
      line-height: 120%;
      
      @media (min-width: 768px) {
        font-size: 28px;
      }
    }

    .screenReaderMobile {
      @media (max-width: 768px )  {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
      }
    }
`

const breakpoints = ['360px', '768px', '1024px', '1280px', '1600px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4]

const mq = breakpoints.map((bp) => `@media (min-width: ${bp})`)

mq.sm = mq[0]
mq.md = mq[1]
mq.lg = mq[2]
mq.xl = mq[3]
mq.xxl = mq[4]

export { mq }

const colors = {
  primary: '#004AAD',
  secondary: '#1C2C36',
  white: '#ffffff',
  black: '#000000',
}

export const theme = {
  breakpoints,
  colors: {
    ...colors,
    header: {
      bg: colors.white,
    },
  },
  fonts: {
    body: "'Pragati Narrow', sans-serif",
    heading: 'inherit',
    a: 'inherit',
  },
  variants: {
    container: {
      maxWidth: `1260px`,
      mx: 'auto',
      px: 30,
    },
  },
  buttons: {
    outline: {
      border: '1px solid',
      bg: 'white',
      color: 'primary',
    },
  },
}
