import React from 'react'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import { theme, globalStyles } from './index'

export const ThemeProvider = ({ children }) => (
  <EmotionThemeProvider theme={theme}>
    <Global
      styles={css`
        ${globalStyles}
      `}
    />
    {children}
  </EmotionThemeProvider>
)
